import { Box, Grid, IconButton, Typography } from "@mui/material"
import Card from "components/Card/Card"
import AppButton from "components/StyledComponents/AppButton"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Slider from "@mui/material/Slider"
import SelectMenuItems from "./components/QuestionsModal"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import { useDispatch, useSelector } from "react-redux"
import { useTheme } from "@mui/system"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { generateQuestionAction } from "redux/L&D/actions/user"
import QuestionsBanks from "pages/private/learning_development/QuestionBank/components/QuestionBanks"
import { makeStyles } from "@material-ui/core"
import alternate from "assets/images/imagePlaceholder.png"
import styles from "./GenerateQyestion.module.scss"

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSlider-thumb": {
      color: "var(--status-negative, #CC4B37)"
    },
    "& .MuiSlider-mark": {
      display: "none"
    }
  }
}))

const GenerateQuestion = () => {
  const theme = useTheme()
  const sliderClass = useStyles()
  const { userRestaurant } = useSelector((store) => store.user)
  function valuetext(value) {
    return `${value}`
  }
  const dispatch = useDispatch()
  const { selectedMenuItems, generatedQuestions } = useSelector(
    (state) => state?.learningDevelopment || []
  )

  const [noOfQuestion, setNoOfQuestions] = useState(1)
  const [questionModal, setQuestionModal] = useState(false)

  const handleSelectMenuItems = () => {
    setQuestionModal((prevQuestionModal) => !prevQuestionModal)
    dispatch(learningDevelopmentActions.setGenerate_Question_MenuLink("dinner"))
  }

  const handleRemoveMenuItem = (ID) => {
    const updatedList = selectedMenuItems.filter((item) => item?.id !== ID)
    dispatch(learningDevelopmentActions.setSelectedMenuItems(updatedList))
  }

  const handleQuestionCount = (event) => {
    setNoOfQuestions(event.target.value)
  }

  //generate question on the basis of menu items
  const handleGenerateQuestion = () => {
    let menu_ids = []
    for (var k = 0; k < selectedMenuItems.length; k++) {
      menu_ids.push({ id: selectedMenuItems?.[k]?.id, type: selectedMenuItems?.[k]?.category })
    }
    const payload = {
      menu_ids,
      question_number: noOfQuestion,
      platform: userRestaurant?.name ?? "SCARPETTA"
    }
    dispatch(generateQuestionAction(payload))
  }

  useEffect(() => {
    dispatch(learningDevelopmentActions.setSelectedMenuItems([]))
    dispatch(learningDevelopmentActions.setGeneratedQuestions([]))
  }, [])

  return (
    <>
      {questionModal && <SelectMenuItems onClose={handleSelectMenuItems} />}
      <Card>
        <Box>
          <Row>
            <Typography variant="caption2">Step 1</Typography>
          </Row>
          <Row className={selectedMenuItems?.length > 0 ? "mt-4" : "mt-0"}>
            <Col lg="12">
              <Grid container spacing={2}>
                {selectedMenuItems?.map((item) => (
                  <Grid item xs={3} key={item?.id}>
                    <Grid container className={styles.itemContainer} justifyContent="space-between">
                      <Grid item>
                        <img
                          className={styles.selectedItemsImage}
                          src={
                            item?.image_url
                              ? item?.image_url
                              : item?.image
                              ? item?.image
                              : alternate
                          }
                        />
                      </Grid>
                      <Grid item xs={7} xl={9} className={styles.selectedItemsText}>
                        <Typography variant="h4">
                          {item?.name && item.name.length > 23
                            ? `${item.name.substring(0, 20)}...`
                            : item?.name || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} className={styles.closeIcon}>
                        <IconButton
                          sx={{ color: theme.palette.text.primary }}
                          onClick={() => handleRemoveMenuItem(item?.id)}>
                          <HighlightOffIcon sx={{ height: "20px", width: "20px" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <AppButton onClick={handleSelectMenuItems} styles={{ padding: "10px 30px" }}>
                {selectedMenuItems?.length > 0 ? `Add Menu Items` : `Select Menu Items`}
              </AppButton>
            </Col>
          </Row>
          <Row className="mt-4">
            <Typography variant="caption2">Step 2</Typography>
          </Row>
          <Row className="mt-2 mb-1">
            <Typography variant="body1Regular" color={theme.palette.text.secondary}>
              Number of Questions per item
            </Typography>
          </Row>
          <Row>
            <Col lg="3">
              <Slider
                className={sliderClass.root}
                aria-label="Always visible"
                defaultValue={1}
                getAriaValueText={valuetext}
                step={1}
                marks={marks}
                valueLabelDisplay="off"
                color="secondary"
                min={1}
                max={5}
                value={noOfQuestion}
                onChange={handleQuestionCount}
              />
            </Col>
          </Row>
        </Box>
      </Card>
      <Row className="mt-4">
        <Col>
          <AppButton
            disabled={selectedMenuItems?.length > 0 ? false : true}
            onClick={handleGenerateQuestion}>
            Generate Questions
          </AppButton>
        </Col>
      </Row>
      {generatedQuestions?.length > 0 && (
        <Row className="mt-4">
          <Col lg="12">
            <QuestionsBanks
              title="Question Bank"
              questions={generatedQuestions}
              showImage={false}
              showPagination={false}
              isGenerateQuestion={true}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

const marks = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 2,
    label: "2"
  },
  {
    value: 3,
    label: "3"
  },
  {
    value: 4,
    label: "4"
  },
  {
    value: 5,
    label: "5"
  }
]

export default GenerateQuestion
