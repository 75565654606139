import axios from "axios"
import { call, put, select } from "redux-saga/effects"
import { toast } from "react-toastify"
import slice from "../slice/user"
import calendarSlice from "../../L&D/slice/user"
import { appActions } from "redux/app/slice/app"
import { formatDate } from "helpers/functions"
import { GMActions } from "redux/GM/slice/user"
import DMV_Slice from "../../DMV/slice/user"
import moment from "moment"

const baseUrl = process.env.REACT_APP_API_MY_URL

export function* raffleTicket(action) {
  try {
    yield call(axios.post, `${baseUrl}/frontline/raffle/`, action.payload)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/`)
    if (data.success) {
      yield put(slice.actions.setRaffleListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadWineOfTheMonthThumbnail(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setWineMonthVideoThumbnail(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}
export function* uploadPDFThumbnail(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setWineMonthPDFThumbnail(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadWineOfTheMonth(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const date = yield select((store) => store.app?.filters?.date)
    const { data } = yield call(axios.post, `${baseUrl}/frontline/featured-item/`, action.payload)
    if (data.success) {
      const dateObject = new Date(date)
      const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
      const alcohol_type = yield select((store) => store.app?.filters?.alcohol_type)
      let params = ""
      if (alcohol_type && alcohol_type !== "All") {
        params = `&alcohol_type=${alcohol_type}`
      }
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/featured-item/?month=${formatDate(newDate)}${params}`
      )
      if (data.success) {
        yield put(slice.actions.setWineMonthListData(data.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
  yield put(slice.actions.setWineMonthPDFThumbnail(""))
  yield put(slice.actions.setWineMonthVideoThumbnail(""))
}

export function* getListWineMonth(action) {
  try {
    const { date, alcohol_type } = action.payload
    let params = date ? `month=${date}` : `month=${moment(new Date()).format("YYYY-MM-DD")}`
    if (alcohol_type && alcohol_type !== "All") {
      params += `&alcohol_type=${alcohol_type}`
    }
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/featured-item/?${params}`)
    if (data.success) {
      yield put(slice.actions.setWineMonthListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteWineOfTheMonthItem(action) {
  yield put(appActions.setIsLoading(true))
  const date = yield select((store) => store.app?.filters?.date)
  try {
    yield call(axios.delete, `${baseUrl}/frontline/featured-item/${action.payload}`)
    const dateObject = new Date(date)
    const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
    const alcohol_type = yield select((store) => store.app?.filters?.alcohol_type)
    let params = ""
    if (alcohol_type && alcohol_type !== "All") {
      params = `&alcohol_type=${alcohol_type}`
    }
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/featured-item/?month=${formatDate(newDate)}${params}`
    )
    if (data.success) {
      toast.success("Featured item deleted successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setWineMonthListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getSingleWineMonthData() {
  try {
    const id = yield select((store) => store?.marketing?.featureItemMonth?.featureItemId ?? "")
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/featured-item/${id}`)
    if (data.success) {
      yield put(slice.actions.setGetSingleWineMonthData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editWineOfTheMonthItem(action) {
  const date = yield select((store) => store.app?.filters?.date)
  const alcohol_type = yield select((store) => store.app?.filters?.alcohol_type)
  let params = ""
  if (alcohol_type && alcohol_type !== "All") {
    params = `&alcohol_type=${alcohol_type}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const id = yield select((store) => store.marketing?.featureItemMonth?.featureItemId)
    yield call(axios.patch, `${baseUrl}/frontline/featured-item/${id}`, action.payload)
    const dateObject = new Date(date)
    const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/featured-item/?month=${formatDate(newDate)}${params}`
    )
    if (data.success) {
      const { data: singleData } = yield call(axios.get, `${baseUrl}/frontline/featured-item/${id}`)
      yield put(slice.actions.setGetSingleWineMonthData(singleData.data))
      yield put(slice.actions.setWineMonthListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
  yield put(slice.actions.setWineMonthPDFThumbnail(""))
  yield put(slice.actions.setWineMonthVideoThumbnail(""))
}

export function* editRaffleContest(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const raffleID = yield select((store) => store?.marketing?.raffleContestData.id)

    yield call(axios.patch, `${baseUrl}/frontline/raffle/${raffleID}/`, action.payload)

    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/`)
    if (data.success) {
      yield put(slice.actions.setRaffleListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getRaffleListData() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/`)
    if (data.success) {
      yield put(slice.actions.setRaffleListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteRaffleContest() {
  const raffleId = yield select((store) => store.marketing?.raffleContestData?.id)
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/raffle/${raffleId}/`)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/`)
    if (data.success) {
      yield put(slice.actions.setRaffleListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getRaffleSingleData() {
  try {
    yield put(appActions.setIsLoading(true))
    const raffleId = yield select((store) => store.marketing?.raffleContestData?.id)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/${raffleId}`)
    if (data.success) {
      yield put(slice.actions.setRaffleSingleData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addCommentCalender(action) {
  const { filters } = yield select((store) => store?.app || {})
  const dateObject = new Date(filters?.date)
  const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
  let params = `date=${formatDate(newDate)}`
  if (filters?.restaurant && filters?.restaurant !== "All") {
    params += `&restaurant=${filters?.restaurant}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/trivia-note/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: calendarData } = yield call(
        axios.get,
        `${baseUrl}/trivia/calendar-api/?${params}`
      )
      if (calendarData.success) {
        yield put(calendarSlice.actions.setCalendarData(calendarData.data || []))
        yield put(GMActions.setGMCalendarData(calendarData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* EditCommentCalender(action) {
  const { filters } = yield select((store) => store?.app || {})
  const dateObject = new Date(filters?.date)
  const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
  let params = `date=${formatDate(newDate)}`
  if (filters?.restaurant && filters?.restaurant !== "All") {
    params += `&restaurant=${filters?.restaurant}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/trivia-note/${action.payload.id}/`,
      {
        note: action.payload.note
      }
    )
    if (data.success) {
      const { data: calendarData } = yield call(
        axios.get,
        `${baseUrl}/trivia/calendar-api/?${params}`
      )
      if (calendarData.success) {
        yield put(calendarSlice.actions.setCalendarData(calendarData.data || []))
        yield put(GMActions.setGMCalendarData(calendarData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getMenuPeriod(action) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const archived = action?.payload
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}${
        archived ? `&item_state=archived` : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setMenuPeriodData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editMenuCategory(action) {
  const { food_category, mealCategoryId, meal_period, name } = action.payload
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/sub-category/${mealCategoryId}`,
      {
        meal_period: meal_period,
        food_category: food_category,
        name: name
      }
    )
    if (data.success) {
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addMenuCategory(action) {
  const { food_category, meal_period, name } = action.payload
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/sub-category/`, {
      meal_period: meal_period,
      food_category: food_category,
      name: name
    })
    if (data.success) {
      yield put(slice.actions.setMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addMenuItems(action) {
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/food-item/`, payload)
    if (data.success) {
      onClose()
      yield put(slice.actions.setMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteMenuCategory(action) {
  const { mealCategoryId } = action.payload
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.delete, `${baseUrl}/frontline/sub-category/${mealCategoryId}`)
    console.log(data)

    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
    )
    if (newData.success) {
      yield put(slice.actions.setMenuPeriodData(newData.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteMenuItem() {
  const Id = yield select((store) => store?.marketing?.menu?.menu_item_ID || {})
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.delete, `${baseUrl}/frontline/food-item/${Id}`)
    console.log(data)

    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}${
        isArchive === true ? `&item_state=archived` : ""
      }`
    )
    if (newData.success) {
      yield put(slice.actions.setMenuPeriodData(newData.data))
      yield put(slice.actions.setisArchive(false))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getFoodItems() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}`)
    if (data.success) {
      yield put(slice.actions.setFoodItemsDetail(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
    yield put(appActions.setIsLoading(false))
  }
  yield put(appActions.setIsLoading(false))
}

export function* updateFoodItems(action) {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}`,
      payload
    )
    if (data.success) {
      onClose()
      yield put(slice.actions.setFoodItemsDetail(data.data))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}${
          isArchive === true ? `&item_state=archived` : ""
        }`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
      }
      yield put(slice.actions.setisArchive(false))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadMenuItem(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setMenuImageId(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadTastingNotesImages(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload.data)
    if (data.success) {
      yield put(
        slice.actions.setTastingNotesImages({
          index: action.payload.indexValue,
          id: data.data.id
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getLeaderboardData(action) {
  const { date, page, employeeClass, orderBy } = action.payload
  let params = `?month=${date}&page=${page}`
  if (employeeClass && employeeClass !== "All") {
    params += `&job_class=${employeeClass}`
  }
  if (orderBy) {
    params += `&ordering=${orderBy}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/trivia/advance-leaderboard/${params}`)
    if (data.success) {
      yield put(slice.actions.setLoaderBoardData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getSingleUserDataGraph(action) {
  const { userId } = yield select((store) => store?.marketing?.leaderboard || "")
  const { date } = action.payload
  let params = `&month=${date}`
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-score-graph/?user_id=${userId}${params}`
    )
    if (data.success) {
      yield put(slice.actions.setSingleUserDataGraph(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getBarMenu(action) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const archived = action?.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/bar-menu-web${archived ? `?&item_state=archived` : ""}`
    )
    if (data.success) {
      yield put(slice.actions.setBarMenuPeriodData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getWineMenu(action) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const archived = action?.payload
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    if (item === "wine by glass") {
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True${
          archived ? `&item_state=archived` : ""
        }`
      )
      if (data.success) {
        yield put(slice.actions.setWineMenuPeriodData(data.data))
      }
    } else {
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True${
          archived ? `&item_state=archived` : ""
        }`
      )
      if (data.success) {
        yield put(slice.actions.setWineMenuPeriodData(data.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addWineMenuItems(action) {
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    if (item === "wine by glass") {
      const { data } = yield call(
        axios.post,
        `${baseUrl}/frontline/food-item/?wine-by-glass=True`,
        payload
      )
      if (data.success) {
        onClose()
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
    } else {
      const { data } = yield call(
        axios.post,
        `${baseUrl}/frontline/food-item/?wine-by-bottle=True`,
        payload
      )
      if (data.success) {
        onClose()
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editWineMenuItem(action) {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    if (item === "wine by glass") {
      const { data } = yield call(
        axios.patch,
        `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-glass=True`,
        payload
      )
      if (data.success) {
        yield put(slice.actions.setFoodItemsDetail({}))
        onClose()
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True${
            isArchive === true ? `&item_state=archived` : ""
          }`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
      yield put(slice.actions.setisArchive(false))
    } else {
      const { data } = yield call(
        axios.patch,
        `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-bottle=True`,
        payload
      )
      if (data.success) {
        yield put(slice.actions.setFoodItemsDetail({}))
        onClose()
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True${
            isArchive === true ? `&item_state=archived` : ""
          }`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
      yield put(slice.actions.setisArchive(false))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getWineItems() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    if (item === "wine by glass") {
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-glass=True`
      )
      if (data.success) {
        yield put(slice.actions.setFoodItemsDetail(data.data))
      }
    } else {
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-bottle=True`
      )
      if (data.success) {
        yield put(slice.actions.setFoodItemsDetail(data.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteWineMenuItem() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    if (item === "wine by glass") {
      yield call(
        axios.delete,
        `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-glass=True`
      )
      yield put(slice.actions.setFoodItemsDetail({}))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True${
          isArchive === true ? `&item_state=archived` : ""
        }`
      )
      if (newData.success) {
        yield put(slice.actions.setWineMenuPeriodData(newData.data))
      }
    } else {
      yield call(
        axios.delete,
        `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-bottle=True`
      )
      yield put(slice.actions.setFoodItemsDetail({}))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True${
          isArchive === true ? `&item_state=archived` : ""
        }`
      )
      if (newData.success) {
        yield put(slice.actions.setWineMenuPeriodData(newData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addBarMenuItems(action) {
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/food-item/?bar-menu=True`,
      payload
    )
    if (data.success) {
      onClose()
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
      if (newData.success) {
        yield put(slice.actions.setBarMenuPeriodData(newData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editBarMenuItem(action) {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?bar-menu=True`,
      payload
    )
    if (data.success) {
      onClose()
      yield put(slice.actions.setFoodItemsDetail({}))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/bar-menu-web${isArchive === true ? `?item_state=archived` : ""}`
      )
      if (newData.success) {
        yield put(slice.actions.setBarMenuPeriodData(newData.data))
      }
      yield put(slice.actions.setisArchive(false))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getBarItems() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?bar-menu=True`
    )
    if (data.success) {
      yield put(slice.actions.setFoodItemsDetail(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteBarMenuItem() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?bar-menu=True`)
    yield put(slice.actions.setFoodItemsDetail({}))
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/bar-menu-web${isArchive === true ? `?item_state=archived` : ""}`
    )
    if (newData.success) {
      yield put(slice.actions.setBarMenuPeriodData(newData.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* AddBarMenuCategory(action) {
  const { food_category, meal_period, name } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/bar-category/`, {
      meal_period: meal_period,
      food_category: food_category,
      name: name
    })
    if (data.success) {
      yield put(slice.actions.setMenuCategoryId(data.data.id))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
      if (newData.success) {
        yield put(slice.actions.setBarMenuPeriodData(newData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editBarMenuCategory(action) {
  const { food_category, mealCategoryId, meal_period, name } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/bar-category/${mealCategoryId}`,
      {
        meal_period: meal_period,
        food_category: food_category,
        name: name
      }
    )
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
      if (newData.success) {
        yield put(slice.actions.setBarMenuPeriodData(newData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteBarMenuCategory(action) {
  const { mealCategoryId } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.delete, `${baseUrl}/frontline/bar-category/${mealCategoryId}`)
    console.log(data)

    const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
    if (newData.success) {
      yield put(slice.actions.setBarMenuPeriodData(newData.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* unArchiveItems(action) {
  try {
    const { payload, setSelectedItems } = action.payload
    const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/menu-archive/`, payload)
    if (data.success) {
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}&item_state=archived
        `
      )
      if (data.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
        setSelectedItems()
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* unArchiveBarItems(action) {
  try {
    const { payload, setSelectedItems } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/menu-archive/`, payload)
    if (data.success) {
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/bar-menu-web?item_state=archived`
      )
      if (data.success) {
        setSelectedItems()
        yield put(slice.actions.setBarMenuPeriodData(data.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* importSpiritItem(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/spirit-import/`, action.payload)
    if (data.success) {
      const { menuCategory } = yield select((store) => store.dmv)
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing&item_state=available`
      )
      if (newData.success) {
        yield put(slice.actions.setSpiritData(newData?.data || []))
      }
      const { data: subCategoryData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-sub-category/`
      )
      if (subCategoryData.success) {
        yield put(DMV_Slice.actions.setSpiritSubMenuItems(subCategoryData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getSpiritCMS(action) {
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    if (menuCategory?.name) {
      yield put(appActions.setIsLoading(true))
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${
          menuCategory?.name
        }?platform=marketing&item_state=available${
          action.payload !== "" && action.payload ? `&search=${action.payload}` : ""
        }`
      )
      if (data.success) {
        yield put(slice.actions.setSpiritData(data?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getArchivedSpiritCMS() {
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing&item_state=archived`
    )
    if (data.success) {
      yield put(slice.actions.setSpiritData(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* unArchiveSpiritsItems(action) {
  try {
    const { payload, setSelectedItems } = action.payload
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/menu-archive/`, payload)
    if (data.success) {
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing&item_state=archived`
      )
      if (data.success) {
        setSelectedItems()
        yield put(slice.actions.setSpiritData(data.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* archiveSpiritItemsAction(action) {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      const { data: newDta } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing&item_state=archived`
      )
      if (data.success) {
        yield put(slice.actions.setSpiritData(newDta.data))
      }
      const { data: subCategoryData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-sub-category/`
      )
      if (subCategoryData.success) {
        yield put(DMV_Slice.actions.setSpiritSubMenuItems(subCategoryData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* unarchiveWineItem(action) {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    if (item === "wine by glass") {
      const { data } = yield call(
        axios.patch,
        `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-glass=True`,
        action.payload
      )
      if (data.success) {
        yield put(slice.actions.setFoodItemsDetail({}))
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True&item_state=archived`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
    } else {
      const { data } = yield call(
        axios.patch,
        `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-bottle=True`,
        action.payload
      )
      if (data.success) {
        yield put(slice.actions.setFoodItemsDetail({}))
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True&item_state=archived`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addarchiveSpiritItems(action) {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      if (data.success) {
        const { data: newDta } = yield call(
          axios.get,
          `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing${
            isArchive === true ? `&item_state=archived` : `&item_state=available`
          }`
        )
        if (data.success) {
          yield put(slice.actions.setSpiritData(newDta.data))
        }
        yield put(slice.actions.setisArchive(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteSpiritMenuItem() {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/spirit-menu/${itemID}`)
    yield put(slice.actions.setMenuCategoryId(""))
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing${
        isArchive === true ? `&item_state=archived` : "&item_state=available"
      }`
    )
    if (newData.success) {
      yield put(slice.actions.setSpiritData(newData?.data || []))
    }
    const { data: subCategoryData } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-sub-category/`
    )
    if (subCategoryData.success) {
      yield put(DMV_Slice.actions.setSpiritSubMenuItems(subCategoryData.data))
    }
    yield put(slice.actions.setisArchive(false))
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getSpiritItems() {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}?platform=marketing&item_state=available`
    )
    if (data.success) {
      yield put(DMV_Slice.actions.setSpiritMenuItem(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* updateSpiritItems(action) {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing${
          isArchive === true ? `&item_state=archived` : `&item_state=available`
        }`
      )
      if (newData.success) {
        yield put(slice.actions.setSpiritData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getNewSpiritCount() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/spirit-count/`)
    if (data.success) {
      yield put(slice.actions.setNewSpiritCount(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGamificationLeaderboardData(action) {
  const { date, employeeClass, orderBy } = action.payload
  let params = `?month=${date}`
  if (employeeClass && employeeClass !== "All") {
    params += `&job_class=${employeeClass}`
  }
  if (orderBy) {
    params += `&ordering=${orderBy}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/gamification-leaderboard/${params}`
    )
    if (data.success) {
      yield put(slice.actions.setGamificationLoaderBoardData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getQuizLeaderBoardData(action) {
  try {
    const { activeToggle, date, employeeClass, currentPage, weeks, orderBy } = action.payload
    yield put(appActions.setIsLoading(true))
    let params = `?filter_type=${activeToggle}&page=${currentPage}`
    if (date) {
      params += `&month=${date}`
    }
    if (employeeClass && employeeClass !== "All") {
      params += `&employee_class=${employeeClass}`
    }
    if (weeks && weeks.label !== "All") {
      params += `&start_date=${weeks.value.start}&end_date=${weeks.value.end}`
    }
    if (orderBy) {
      params += `&sort_by=${orderBy}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/frontline/quiz/leaderboard${params}`)
    if (data.success) {
      yield put(slice.actions.setQuizLeaderBoardData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getMenuSubCategory() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/food-type/`)
    if (data.success) {
      yield put(slice.actions.setMenuSubCategory(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadMenuBulk(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { csvUrl, menuCategory, onClose } = action.payload
    const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
    const headerParams = { headers: { "Content-Type": "multipart/form-data" } }
    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/menu/bulk-upload?category=${menuCategory}`,
      { file: csvUrl },
      headerParams
    )
    if (data.success) {
      onClose()
      if (
        menuCategory == "Dinner" ||
        menuCategory == "Lunch" ||
        menuCategory == "Brunch" ||
        menuCategory == "Breakfast"
      ) {
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
        )
        if (newData.success) {
          yield put(slice.actions.setMenuPeriodData(newData.data))
        }
      } else if (menuCategory == "Bar Menu") {
        const { data: newBarData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
        if (newBarData.success) {
          yield put(slice.actions.setBarMenuPeriodData(newBarData.data))
        }
      } else if (menuCategory == "Wine By Glass") {
        const { data: newWineData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True`
        )
        if (newWineData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newWineData.data))
        }
      } else {
        const { data: newWineData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True`
        )
        if (newWineData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newWineData.data))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* AddWineMenuCategory(action) {
  const { food_category, meal_period, name } = action.payload
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    if (item === "wine by glass") {
      const { data } = yield call(axios.post, `${baseUrl}/frontline/wine-category/`, {
        meal_period: meal_period,
        food_category: food_category,
        name: name
      })
      if (data.success) {
        yield put(slice.actions.setMenuCategoryId(data.data.id))
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
    } else {
      const { data } = yield call(
        axios.post,
        `${baseUrl}/frontline/wine-category/?wine-by-bottle=True`,
        {
          meal_period: meal_period,
          food_category: food_category,
          name: name
        }
      )
      if (data.success) {
        yield put(slice.actions.setMenuCategoryId(data.data.id))
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editWineMenuCategory(action) {
  const { food_category, mealCategoryId, meal_period, name } = action.payload
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/wine-category/${mealCategoryId}`,
      {
        meal_period: meal_period,
        food_category: food_category,
        name: name
      }
    )
    if (data.success) {
      if (item === "wine by glass") {
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      } else {
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteWineMenuCategory(action) {
  const { mealCategoryId } = action.payload
  const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.delete,
      `${baseUrl}/frontline/wine-category/${mealCategoryId}`
    )
    if (data.success) {
      if (item === "wine by glass") {
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      } else {
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True`
        )
        if (newData.success) {
          yield put(slice.actions.setWineMenuPeriodData(newData.data))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* unArchiveWineItems(action) {
  try {
    const { payload, setSelectedItems } = action.payload
    const { item } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/menu-archive/`, payload)
    if (data.success) {
      if (item === "wine by glass") {
        const { data } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True&item_state=archived`
        )
        if (data.success) {
          setSelectedItems()
          yield put(slice.actions.setWineMenuPeriodData(data.data))
        }
      } else {
        const { data } = yield call(
          axios.get,
          `${baseUrl}/frontline/web-menu-list/?wine-by-bottle=True&item_state=archived`
        )
        if (data.success) {
          setSelectedItems()
          yield put(slice.actions.setWineMenuPeriodData(data.data))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}
