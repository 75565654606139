import React, { useState } from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import ImageDisplay from "components/ImageDisplayer"
import classes from "pages/private/marketing/menu/menu.module.scss"
import Paragraph from "components/ReadMoreParagraph"
import {
  addarchiveSpiritItemsAction,
  deleteSpiritMenuItemAction,
  getSpiritItemsAction,
  unArchiveSpiritsItemsAction
} from "redux/marketing/actions/user"
import UnarchiveItem from "assets/icons/UnarchiveItem.svg"
import SelectAll from "assets/icons/SelectAllRectange.svg"
import unSelect from "assets/icons/unSelectedRectange.svg"
import { formatMenuDate, isEmptyObject } from "helpers/functions"
import selectedItem from "assets/icons/SelectedRectange.svg"
import { toast } from "react-toastify"
import { marketingActions } from "redux/marketing/slice/user"
import DMVAddMenuItems from "pages/private/DVM_Console/DMV_Menu/AddDMVMenuItems"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { DMVActions } from "redux/DMV/slice/user"
import trashIcon from "assets/icons/trash.svg"
import editIcon from "assets/icons/edit.svg"

function ArchivedSpiritListItems({ data, isEdit = false }) {
  const theme = useTheme()
  const [selectedItems, setSelectedItems] = useState({})
  const [modal, setDeleteModal] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [unarchiveModal, setUnarchiveItemModal] = useState(false)
  const dispatch = useDispatch()
  const { archived } = useSelector((store) => store?.marketing?.menu ?? {})

  const handleClick = (type, id, categoryId) => {
    dispatch(DMVActions.setDMVItemID(id))
    dispatch(DMVActions.setDMVMenuCategoryId(categoryId))
    dispatch(getSpiritItemsAction())
    if (type == "edit") {
      handleEditMenuItemModal()
    } else if (type == "archive") {
      handleArchiveItem()
    } else {
      handleMenuDeleteModal()
    }
  }

  function handleEditMenuItemModal() {
    setIsEditModalItem(true)
    setMenuItemsModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  function handleMenuItemDelete() {
    dispatch(deleteSpiritMenuItemAction())
    dispatch(marketingActions.setisArchive(false))
  }

  function handleArchiveItem() {
    dispatch(marketingActions.setisArchive(true))
    setUnarchiveItemModal(true)
  }

  function handleCloseArchiveModal() {
    setUnarchiveItemModal(false)
  }

  function handleUnarchiveMenuItem() {
    const payload = {
      item_state: "available"
    }
    dispatch(addarchiveSpiritItemsAction(payload))
  }

  function handleMenuDeleteModal() {
    setDeleteModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  const handleSelect = (item) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems(item)
    } else {
      setSelectedItems({})
    }
  }

  const handleUnArchive = () => {
    if (isEmptyObject(selectedItems)) {
      toast.error("Please select items to unarchive!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    } else {
      const id_dict = selectedItems?.spirit_list?.map((item) => ({
        id: item.id,
        food_type: "spirits"
      }))
      const payload = {
        id_dict
      }
      const clearState = () => {
        setSelectedItems({})
      }
      dispatch(unArchiveSpiritsItemsAction({ payload, setSelectedItems: clearState }))
    }
  }

  const checkSelectedItems = (id) => {
    const isItemSelected = selectedItems?.spirit_list?.filter((item) => item?.id === id)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleSelectItem = (id) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems({
        ...data,
        spirit_list: data?.spirit_list?.filter((item) => item?.id === id)
      })
    } else {
      const itemsData = selectedItems?.spirit_list?.filter((item) => item?.id === id)
      if (itemsData?.length > 0) {
        if (selectedItems?.spirit_list?.length === 1) {
          setSelectedItems({})
        } else {
          setSelectedItems({
            ...selectedItems,
            spirit_list: selectedItems?.spirit_list?.filter((item) => item?.id !== id)
          })
        }
      } else {
        setSelectedItems({
          ...selectedItems,
          spirit_list: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...selectedItems?.spirit_list,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...data?.spirit_list?.filter((item) => item?.id === id)
          ]
        })
      }
    }
  }
  return (
    <>
      <DMVAddMenuItems
        isEdit={isEditModalItem}
        open={menuItemsModal}
        onClose={handleCloseMenuItems}
        isCMS={true}></DMVAddMenuItems>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this wine ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are you sure you want to unarchive this wine?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleUnarchiveMenuItem()}
        open={unarchiveModal}
        btnText="Unarchive"
        type="warning"
      />
      {data?.spirit_list?.length > 0 ? (
        <Card style={{ height: "fit-content" }}>
          <Row>
            <Col lg="12">
              <div
                className="d-flex justify-content-between align-item-center"
                style={{
                  backgroundColor: isEdit && theme.palette.background.default,
                  padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                  borderRadius: 6
                }}>
                <img
                  src={
                    selectedItems?.spirit_list?.length === data?.spirit_list?.length
                      ? selectedItem
                      : SelectAll
                  }
                  alt="selectAll"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSelect(data)}
                />
                <Typography variant="h2">{data?.spirit_category}</Typography>
                <div className="d-flex align-items-center">
                  <img
                    src={UnarchiveItem}
                    alt="unarchive"
                    style={{ cursor: "pointer" }}
                    onClick={handleUnArchive}
                  />
                </div>
              </div>
            </Col>
            <Col lg="12">
              {data?.spirit_list?.length ? (
                data?.spirit_list?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
                        borderBottom: `${
                          !isEdit
                            ? `1px solid #605e5e`
                            : `.5px solid ${theme.palette.secondary.main}`
                        }`,
                        borderRadius: `${isEdit ? "6px" : "0px"}`,
                        padding: `${isEdit ? "8px" : "5px"}`,
                        margin: "24px 0"
                      }}>
                      <div
                        className={archived ? classes.archivedItemContainer : classes.itemContainer}
                        style={{ margin: 0 }}>
                        <div className={classes.checkbox}>
                          {checkSelectedItems(item?.id) ? (
                            <img
                              src={selectedItem}
                              alt="select"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSelectItem(item?.id)}
                            />
                          ) : (
                            <img
                              src={unSelect}
                              alt="select"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSelectItem(item?.id)}
                            />
                          )}
                        </div>
                        <div className={classes.imageContainer} style={{ height: 250 }}>
                          <ImageDisplay data={item?.image_url}></ImageDisplay>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            maxWidth: 375,
                            overflowWrap: "break-word"
                          }}>
                          <div>
                            <Typography variant="h4">{item?.name ?? "-"}</Typography>
                          </div>

                          <div>
                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Description:"}</Typography>
                              <Typography variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                                {item?.description ?? "-"}
                              </Typography>
                            </div>

                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Region:"}</Typography>
                              <Typography variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                                {item?.origin ?? "-"}
                              </Typography>
                            </div>

                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Unique Facts:"}</Typography>
                              {item?.unique_facts?.length === 1
                                ? item?.unique_facts?.map((item, index) =>
                                    item?.fact !== "" ? (
                                      <Typography
                                        component="div"
                                        style={{
                                          display: item?.fact ? "list-item" : "",
                                          marginLeft: 15
                                        }}
                                        key={index}
                                        variant="menuLabelItalic"
                                        sx={{ opacity: 0.6 }}>
                                        <Paragraph
                                          text={item?.fact ?? ""}
                                          maxCharCount={70}
                                          key={index}
                                          variant="menuLabelItalic"
                                          sx={{ opacity: 0.6 }}></Paragraph>
                                      </Typography>
                                    ) : null
                                  )
                                : item?.unique_facts?.map((item, index) => (
                                    <Typography
                                      component="div"
                                      style={{
                                        display: item?.fact ? "list-item" : "",
                                        marginLeft: 15
                                      }}
                                      key={index}
                                      variant="menuLabelItalic"
                                      sx={{ opacity: 0.6 }}>
                                      <Paragraph
                                        text={item?.fact ?? ""}
                                        maxCharCount={70}
                                        key={index}
                                        variant="menuLabelItalic"
                                        sx={{ opacity: 0.6 }}></Paragraph>
                                    </Typography>
                                  ))}
                            </div>

                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Ingredients:"}</Typography>
                              {item?.ingredient?.map((item, index) => (
                                <Typography
                                  key={index}
                                  variant="menuLabelItalic"
                                  sx={{ opacity: 0.6 }}>
                                  {`${item?.name ?? ""}`}
                                  {item?.measurement
                                    ? item?.ingredient?.length - 1 > index
                                      ? ` (${item?.measurement})`
                                      : item?.measurement
                                      ? ` (${item?.measurement}) `
                                      : ""
                                    : item?.ingredient?.length - 1 > index
                                    ? ", "
                                    : ""}
                                </Typography>
                              ))}
                            </div>

                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Nose:"}</Typography>
                              <Typography
                                component="div"
                                variant="menuLabelItalic"
                                sx={{ opacity: 0.6 }}>
                                {typeof item?.nose === "string" && item?.nose
                                  ? item.nose?.includes("\n")
                                    ? item.nose
                                        .split("\n")
                                        .map((line, index) =>
                                          line.length > 70 ? (
                                            <Paragraph key={index} text={line} maxCharCount={70} />
                                          ) : (
                                            <div key={index}>{line}</div>
                                          )
                                        )
                                    : item.nose
                                  : ""}
                              </Typography>
                            </div>

                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Palate:"}</Typography>
                              <Typography
                                component="div"
                                variant="menuLabelItalic"
                                sx={{ opacity: 0.6 }}>
                                {typeof item?.palate === "string" && item?.palate
                                  ? item.palate?.includes("\n")
                                    ? item.palate
                                        .split("\n")
                                        .map((line, index) =>
                                          line.length > 70 ? (
                                            <Paragraph key={index} text={line} maxCharCount={70} />
                                          ) : (
                                            <div key={index}>{line}</div>
                                          )
                                        )
                                    : item.palate
                                  : ""}
                              </Typography>
                            </div>
                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Finish:"}</Typography>
                              <Typography
                                component="div"
                                variant="menuLabelItalic"
                                sx={{ opacity: 0.6 }}>
                                {typeof item?.finish === "string" && item?.finish
                                  ? item.finish?.includes("\n")
                                    ? item.finish
                                        .split("\n")
                                        .map((line, index) =>
                                          line.length > 70 ? (
                                            <Paragraph key={index} text={line} maxCharCount={70} />
                                          ) : (
                                            <div key={index}>{line}</div>
                                          )
                                        )
                                    : item.finish
                                  : ""}
                              </Typography>
                            </div>
                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Distillation:"}</Typography>
                              <Typography
                                component="div"
                                variant="menuLabelItalic"
                                sx={{ opacity: 0.6 }}>
                                {typeof item?.distillation === "string" && item?.distillation
                                  ? item.distillation?.includes("\n")
                                    ? item.distillation
                                        .split("\n")
                                        .map((line, index) =>
                                          line.length > 70 ? (
                                            <Paragraph key={index} text={line} maxCharCount={70} />
                                          ) : (
                                            <div key={index}>{line}</div>
                                          )
                                        )
                                    : item.distillation
                                  : ""}
                              </Typography>
                            </div>
                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Aging:"}</Typography>
                              <Typography
                                component="div"
                                variant="menuLabelItalic"
                                sx={{ opacity: 0.6 }}>
                                {typeof item?.aging === "string" && item?.aging
                                  ? item.aging?.includes("\n")
                                    ? item.aging
                                        .split("\n")
                                        .map((line, index) =>
                                          line.length > 70 ? (
                                            <Paragraph key={index} text={line} maxCharCount={70} />
                                          ) : (
                                            <div key={index}>{line}</div>
                                          )
                                        )
                                    : item.aging
                                  : ""}
                              </Typography>
                            </div>
                            <div className="mt-2 d-flex flex-column">
                              <Typography variant="menuLabel">{"Bottling:"}</Typography>
                              <Typography
                                component="div"
                                variant="menuLabelItalic"
                                sx={{ opacity: 0.6 }}>
                                {typeof item?.bottling === "string" && item?.bottling
                                  ? item.bottling?.includes("\n")
                                    ? item.bottling
                                        .split("\n")
                                        .map((line, index) =>
                                          line.length > 70 ? (
                                            <Paragraph key={index} text={line} maxCharCount={70} />
                                          ) : (
                                            <div key={index}>{line}</div>
                                          )
                                        )
                                    : item.bottling
                                  : ""}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className={classes.menu_action_grid}>
                          <div className="d-grid justify-content-center">
                            {item?.item_price > 0 && (
                              <Typography variant="bold">${item?.item_price}</Typography>
                            )}
                          </div>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              src={editIcon}
                              alt="edit"
                              onClick={() =>
                                handleClick("edit", item?.id, data?.spirit_category_id)
                              }
                              className={classes.menu_action_icon}
                            />
                          </div>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              src={UnarchiveItem}
                              alt="unarchive"
                              className={classes.menu_action_icon}
                              onClick={() =>
                                handleClick("archive", item?.id, data?.spirit_category_id)
                              }
                            />
                          </div>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              onClick={() =>
                                handleClick("delete", item?.id, data?.spirit_category_id)
                              }
                              className={classes.menu_action_icon}
                              src={trashIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <div>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: 14,
                              fontWeight: "400"
                            }}>
                            Added:{" "}
                            <Typography
                              sx={(theme) => ({
                                fontSize: 14,
                                fontWeight: "400",
                                color: theme.palette.orange.tertiary,
                                cursor: "pointer"
                              })}
                              variant="tag1">
                              {formatMenuDate(item?.created_at)}
                            </Typography>
                          </Typography>
                        </div>
                        {item?.updated_at ? (
                          <div>
                            <Typography
                              component="div"
                              sx={{
                                fontSize: 14,
                                fontWeight: "400"
                              }}>
                              Updated:{" "}
                              <Typography
                                sx={(theme) => ({
                                  fontSize: 14,
                                  fontWeight: "400",
                                  color: theme.palette.orange.tertiary,
                                  cursor: "pointer"
                                })}
                                variant="tag1">
                                {formatMenuDate(item?.updated_at)}
                              </Typography>
                            </Typography>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })
              ) : (
                <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                  No Items Found
                </Typography>
              )}
            </Col>
          </Row>
        </Card>
      ) : (
        <Typography>No Items Found</Typography>
      )}
    </>
  )
}

export default React.memo(ArchivedSpiritListItems)
