import { Grid, Tooltip, Typography, styled, useTheme } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import AppSelect from "components/StyledComponents/AppSelect"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import { Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { EditGamificationAction } from "redux/superAdmin/actions"
import { validationSchemaGamification } from "helpers/validationSchema"

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,

  "& span": {
    color: theme.palette.error.main
  }
}))

const initData = {
  profile_picture: 0,
  featured_item: 0,
  tasting_notes: 0,
  flash_cards: 0,
  trivia_award: 0,
  event_rsvp: 0,
  review: 0,
  positive_review: 0,
  quiz_review: 0
}

const EditGamification = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialFormValue, setInitialFormValue] = useState(initData)
  const { gamificationData } = useSelector((store) => store.superAdmin || [])
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()

  function initFormData() {
    const newData =
      gamificationData?.length === 0
        ? initData
        : gamificationData?.reduce((acc, curr) => {
            acc[curr.name] = curr.coins
            return acc
          }, {})
    const initialValues = {
      profile_picture: newData?.PROFILE_PICTURE,
      featured_item: newData?.FEATURED_ITEM,
      tasting_notes: newData?.TASTING_NOTES,
      flash_cards: newData?.FLASH_CARDS,
      trivia_award: newData?.TRIVIA_AWARD,
      event_rsvp: newData?.EVENT_RSVP,
      review: newData?.REVIEW,
      positive_review: newData?.POSITIVE_REVIEW,
      quiz_review: newData?.QUIZ_REWARD
    }
    setInitialFormValue(initialValues)
  }

  function handleSubmit(values) {
    const gamification_data = Object.keys(values).map((key) => {
      let name = key.toUpperCase()
      let coins = values[key]

      return { name, coins }
    })

    const payload = { gamification_data }
    dispatch(EditGamificationAction(payload))
    history.replace("/super-admin/dashboard/gamification")
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    initFormData()
    document.title = "Gamification"
  }, [])

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Gamification" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaGamification}
        initialValues={initialFormValue}>
        {(formik) => (
          <Container fluid className="mt-2">
            <Row>
              <Col lg="4" className="mt-md-2">
                <StyledLabel variant="body1Regular">
                  Upload Profile Photo <span>*</span>
                  <Tooltip title="Upload Profile Photo task will be available to the users only one time. The coins will be credited as soon as the users upload their profile picture.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Col lg="12" md="10" className="mt-2">
                  <AppSelect
                    id="profile_picture"
                    name="profile_picture"
                    isRequired={true}
                    menuItems={COINS}
                    value={formik.values.profile_picture}
                    onChange={formik.handleChange}
                    error={formik.errors.profile_picture}
                    touched={formik.touched.profile_picture}
                  />
                </Col>
              </Col>
              <Col lg="4" className="mt-md-2">
                <StyledLabel variant="body1Regular">
                  Featured Item of the Month <span>*</span>
                  <Tooltip title="View Feature item of the Month's video task will be available to the users once a week for the current featured item of the Month. The week duration is set from Monday to Sunday.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Row>
                  <Col lg="9" md="10" className="mt-2">
                    <AppSelect
                      id="featured_item"
                      name="featured_item"
                      isRequired={true}
                      menuItems={COINS}
                      value={formik.values?.featured_item}
                      onChange={formik.handleChange}
                      error={formik.errors.featured_item}
                      touched={formik.touched.featured_item}
                    />
                  </Col>
                  <Col lg="3" md="2" className="p-0 d-flex align-items-center">
                    <Typography variant="body1Regular">/Week</Typography>
                  </Col>
                </Row>
              </Col>
              <Col lg="4" className="mt-md-2">
                <StyledLabel variant="body1Regular">
                  Tasting Notes Submission <span>*</span>
                  <Tooltip title="Tasting notes submission task will be available to the users once per month. Users can earn extra coins once in a month by submitting the tasting notes for the current month.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Col lg="12" md="10" className="mt-2">
                  <AppSelect
                    id="tasting_notes"
                    name="tasting_notes"
                    isRequired={true}
                    menuItems={TASTING_COINS}
                    value={formik.values?.tasting_notes}
                    onChange={formik.handleChange}
                    error={formik.errors.tasting_notes}
                    touched={formik.touched.tasting_notes}
                  />
                </Col>
              </Col>
            </Row>
            <Row className="mt-lg-5 mt-md-2">
              <Col lg="4">
                <StyledLabel variant="body1Regular">
                  Flash Cards <span>*</span>
                  <Tooltip title="Flash card view task will be available to the users once per day. The total duration of the task will be between the end of current month's raffle till the beginning of next month's trivia.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Row>
                  <Col lg="9" md="10" className="mt-2">
                    <AppSelect
                      id="flash_cards"
                      name="flash_cards"
                      isRequired={true}
                      menuItems={COINS}
                      value={formik.values.flash_cards}
                      onChange={formik.handleChange}
                      error={formik.errors.flash_cards}
                      touched={formik.touched.flash_cards}
                    />
                  </Col>
                  <Col lg="3" md="2" className="p-0 d-flex align-items-center">
                    <Typography variant="body1Regular">/Day</Typography>
                  </Col>
                </Row>
              </Col>
              <Col lg="4" className="mt-md-2">
                <StyledLabel variant="body1Regular">
                  Weekly Trivia Award <span>*</span>
                  <Tooltip title="Weekly trivia award will be granted to the users every week during the ongoing Trivia contest. Users will be able to earn extra coins when they exhaust their weekly attempts within that week itself.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Row>
                  <Col lg="9" md="10" className="mt-2">
                    <AppSelect
                      id="trivia_award"
                      name="trivia_award"
                      isRequired={true}
                      menuItems={COINS}
                      value={formik.values.trivia_award}
                      onChange={formik.handleChange}
                      error={formik.errors.trivia_award}
                      touched={formik.touched.trivia_award}
                    />
                  </Col>
                  <Col lg="3" md="2" className="p-0 d-flex align-items-center">
                    <Typography variant="body1Regular">/Week</Typography>
                  </Col>
                </Row>
              </Col>
              <Col lg="4" className="mt-md-2">
                <StyledLabel variant="body1Regular">
                  Event RSVP <span>*</span>
                  <Tooltip title="Determine the quantity of coins allocated to users who exclusively confirm their attendance to the event.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Col lg="12" md="10" className="mt-2">
                  <AppSelect
                    id="event_rsvp"
                    name="event_rsvp"
                    isRequired={true}
                    menuItems={COINS}
                    value={formik.values?.event_rsvp}
                    onChange={formik.handleChange}
                    error={formik.errors.event_rsvp}
                    touched={formik.touched.event_rsvp}
                  />
                </Col>
              </Col>
            </Row>
            <Row className="mt-lg-5 mt-md-2">
              <Col lg="4" className="mt-md-2">
                <StyledLabel variant="body1Regular">
                  5-Star Review <span>*</span>
                  <Tooltip title="User will be given coins after providing a positive review.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Col lg="12" md="10" className="mt-2">
                  <AppSelect
                    id="review"
                    name="review"
                    isRequired={true}
                    menuItems={REVIEW}
                    value={formik.values.review}
                    onChange={formik.handleChange}
                    error={formik.errors.review}
                    touched={formik.touched.review}
                  />
                </Col>
              </Col>
              <Col lg="4" className="mt-md-2">
                <StyledLabel variant="body1Regular">
                  5-Star Review with Positive Verbatim <span>*</span>
                  <Tooltip title="User will be given coins after providing a positive review.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Col lg="12" md="10" className="mt-2">
                  <AppSelect
                    id="positive_review"
                    name="positive_review"
                    isRequired={true}
                    menuItems={REVIEW}
                    value={formik.values.positive_review}
                    onChange={formik.handleChange}
                    error={formik.errors.positive_review}
                    touched={formik.touched.positive_review}
                  />
                </Col>
              </Col>
              <Col lg="4" className="mt-md-2">
                <StyledLabel variant="body1Regular">
                  Quiz Reward <span>*</span>
                  <Tooltip title="User will be given coins after providing a positive review.">
                    <InfoIcon sx={{ color: theme.palette.secondary.main, width: "16px" }} />
                  </Tooltip>
                </StyledLabel>
                <Col lg="12" md="10" className="mt-2">
                  <AppSelect
                    id="quiz_review"
                    name="quiz_review"
                    isRequired={true}
                    menuItems={REVIEW}
                    value={formik.values.quiz_review}
                    onChange={formik.handleChange}
                    error={formik.errors.quiz_review}
                    touched={formik.touched.quiz_review}
                  />
                </Col>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg="12">
                <AppButton onClick={formik.handleSubmit} style={{ padding: "14px 70px" }}>
                  Save
                </AppButton>
              </Col>
            </Row>
          </Container>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default EditGamification

const COINS = [
  { label: "1 Coin", value: 1 },
  { label: "5 Coins", value: 5 },
  { label: "10 Coins", value: 10 },
  { label: "15 Coins", value: 15 },
  { label: "20 Coins", value: 20 },
  { label: "25 Coins", value: 25 }
]

const TASTING_COINS = [
  { label: "1 Coin", value: 1 },
  { label: "5 Coins", value: 5 },
  { label: "10 Coins", value: 10 },
  { label: "15 Coins", value: 15 },
  { label: "20 Coins", value: 20 },
  { label: "25 Coins", value: 25 },
  { label: "50 Coins", value: 50 }
]

const REVIEW = [
  { label: "1 Coin", value: 1 },
  { label: "5 Coins", value: 5 },
  { label: "10 Coins", value: 10 },
  { label: "15 Coins", value: 15 },
  { label: "20 Coins", value: 20 },
  { label: "25 Coins", value: 25 },
  { label: "50 Coins", value: 50 },
  { label: "100 Coins", value: 100 }
]
