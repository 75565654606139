// default app theme and colors

export const defaultTheme = {
  palette: {
    primary: {
      main: "#231E1B",
      hover: "#3A312C"
    },
    secondary: {
      main: "#AD916F",
      default: "##AD916F"
    },
    drawer: {
      main: "#231E1B"
    },
    error: {
      main: "#A9402F"
    },
    background: {
      default: "#1D1916",
      main: "#120E0C",
      secondary: "#231E1B"
    },
    white: {
      main: "#ffffff"
    },
    orange: {
      main: "#FFAE00",
      secondary: "#E67C1B",
      tertiary: "#AE8B3A"
    },
    green: {
      main: "#3ADB76",
      light: "#9CC43B"
    },
    red: {
      main: "#CC4B37"
    },
    aqua: {
      main: "#4FACE9"
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#C4C4C4"
    },
    success: {
      main: "#3ADB76"
    },
    linearGradient: {
      main: "linear-gradient(180deg, #675950 0%, #46372F 100%)"
    }
  },
  typography: {
    h1: {
      fontFamily: "Inter",
      fontSize: 40,
      lineHeight: "100%",
      fontWeight: 600
    },
    h2: {
      fontFamily: "PLayfairDisplay-Regular",
      fontSize: 24,
      lineHeight: "140%",
      fontWeight: 400
    },
    h3: {
      fontFamily: "PLayfairDisplay-Regular",
      fontSize: 20,
      lineHeight: "140%",
      fontWeight: 400
    },
    h3Italic: {
      fontFamily: "PLayfairDisplay-Italic",
      fontSize: 20,
      lineHeight: "140%",
      fontWeight: 400
    },
    h4: {
      fontFamily: "PLayfairDisplay-Regular",
      fontSize: 18,
      lineHeight: "140%",
      fontWeight: 400
    },
    h4Italic: {
      fontFamily: "PLayfairDisplay-Regular Italic",
      fontSize: 18,
      lineHeight: "140%",
      fontWeight: 400
    },
    h5: {
      fontFamily: "Inter-Regular",
      fontSize: 18,
      lineHeight: "140%",
      fontWeight: 400,
      fontStyle: "normal",
      color: "#C4C4C4"
    },
    h6: {
      fontFamily: "Inter-Regular",
      fontSize: 18,
      lineHeight: "140%",
      fontWeight: 600,
      fontStyle: "normal",
      color: "#ffff"
    },
    body1small: {
      fontSize: 16,
      fontFamily: "Inter-Medium",
      fontWeight: 400,
      lineHeight: "100%",
      color: "#C4C4C4"
    },
    body1Medium: {
      fontFamily: "Inter-Medium",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "140%"
    },
    body2Medium: {
      fontFamily: "Inter-Medium",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "140%"
    },
    body3Medium: {
      fontFamily: "Inter-Medium",
      fontWeight: 400,
      fontSize: 24,
      lineHeight: "140%"
    },
    body4Medium: {
      fontFamily: "Inter-Medium",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "140%"
    },
    body1Large: {
      fontFamily: "Inter-Medium",
      fontWeight: 500,
      fontSize: 18,
      lineHeight: "140%"
    },
    body1Regular: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "140%"
    },
    body2Regular: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "140%"
    },
    body2Light: {
      fontFamily: "Inter-Regular",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "140%"
    },
    caption1: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "140%",
      letterSpacing: "1px",
      textTransform: "uppercase",
      color: "#A47F51"
    },
    caption2: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "140%"
    },
    caption3: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 12,
      lineHeight: "140%",
      color: "#C4C4C4",
      opacity: 0.6
    },
    caption4: {
      fontFamily: "Inter-Regular",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "140%",
      color: "#fff"
    },
    caption5: {
      fontFamily: "Inter-Regular",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "140%",
      color: "#fff"
    },
    caption6: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 18,
      fontStyle: "normal",
      lineHeight: "140%"
    },
    caption7: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 12,
      fontStyle: "normal",
      lineHeight: "140%",
      color: "#C4C4C4",
      textTransform: "uppercase"
    },
    tag1: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "140%"
    },
    tagItalic: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontStyle: "italic",
      fontSize: 12,
      lineHeight: "140%"
    },
    emphasisText: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 10,
      letterSpacing: 1,
      lineHeight: "140%"
    },
    tag2: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 8,
      lineHeight: "125%"
    },
    tag3: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 8,
      lineHeight: "125%"
    },
    bold: {
      fontFamily: "Inter-Regular",
      fontWeight: 700,
      fontSize: 18,
      lineHeight: "25.2px",
      color: "#AE8B3A"
    },
    menuLabel: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 12
    },
    menuLabelItalic: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 12,
      fontStyle: "italic"
    }
  },
  shadows: [
    "1",
    "2",
    "3",
    "4",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none"
  ],
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Inter-Regular"
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: "PLayfairDisplay-Regular",
          fontSize: 14
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "#120E0C"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#120E0C"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        label: {
          color: "#ffffff"
        }
      }
    }
  },
  overrides: {
    MuiPickersToolbar: {
      fontFamily: "Open Sans",
      height: "48",
      toolbar: {
        backgroundColor: "#5775E5"
      }
    },
    MuiPickersDay: {
      day: {
        color: "#616161"
      },
      daySelected: {
        backgroundColor: "#5775E5"
      },
      dayDisabled: {
        color: "#d4d4d4"
      },
      current: {
        color: "#5775E5"
      }
    }
  }
}

export const defaultThemeDVM = {
  palette: {
    primary: {
      main: "#353535"
    },
    secondary: {
      default: "#4FACE9",
      main: "#274C9A"
    },
    drawer: {
      main: "#15181a"
    },
    error: {
      main: "#A9402F"
    },
    background: {
      default: "#1c1f21",
      main: "#0F1214",
      secondary: "#212121"
    },
    white: {
      main: "#ffffff"
    },
    orange: {
      main: "#FFAE00",
      secondary: "#E67C1B"
    },
    green: {
      main: "#3ADB76",
      light: "#9CC43B"
    },
    red: {
      main: "#CC4B37"
    },
    aqua: {
      main: "#4FACE9"
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#C4C4C4"
    },
    success: {
      main: "#3ADB76"
    },
    linearGradient: {
      main: "linear-gradient(97.14deg, #4FACE9 -118.91%, #274C9A 64.39%);"
    },
    dmv: {
      main: "var(--dmv-secondary, #1C1F21)"
    }
  },
  typography: {
    h1: {
      fontFamily: "Garamond",
      fontSize: 36,
      lineHeight: "100%",
      fontStyle: "italic",
      fontWeight: 400
    },
    h2: {
      fontFamily: "Garamond",
      fontSize: 26,
      lineHeight: "100%",
      fontStyle: "italic",
      fontWeight: 400
    },
    h3: {
      fontFamily: "Garamond",
      fontSize: 24,
      lineHeight: "100%",
      fontStyle: "italic",
      fontWeight: 400
    },
    h3Italic: {
      fontFamily: "PlayfairDisplay-Italic",
      fontSize: 20,
      lineHeight: "140%",
      fontWeight: 400
    },
    h4: {
      fontFamily: "Inter-Regular",
      fontSize: 18,
      lineHeight: "140%",
      fontWeight: 400
    },
    h4Italic: {
      fontFamily: "PlayfairDisplay-Italic",
      fontSize: 18,
      lineHeight: "140%",
      fontWeight: 400
    },
    body1Medium: {
      fontFamily: "Inter-Medium",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "140%"
    },
    body1Regular: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "140%"
    },
    body2Regular: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "140%"
    },
    body2Light: {
      fontFamily: "Inter-Regular",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "140%"
    },
    caption1: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "140%",
      letterSpacing: "1px",
      textTransform: "uppercase",
      color: "#4FACE9"
    },
    caption2: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "140%"
    },
    caption3: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 12,
      lineHeight: "140%",
      color: "#C4C4C4",
      opacity: 0.6
    },
    caption4: {
      fontFamily: "Inter-Regular",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "140%",
      color: "#fff"
    },
    caption5: {
      fontFamily: "Inter-Regular",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "140%",
      color: "#fff"
    },
    tag1: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "140%"
    },
    emphasisText: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 10,
      letterSpacing: 1,
      lineHeight: "140%"
    },
    tag2: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontSize: 8,
      lineHeight: "125%"
    },
    tag3: {
      fontFamily: "Inter-Regular",
      fontWeight: 600,
      fontSize: 8,
      lineHeight: "125%"
    },
    tagItalic: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
      fontStyle: "italic",
      fontSize: 12,
      lineHeight: "140%"
    }
  },
  shadows: [
    "1",
    "2",
    "3",
    "4",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none"
  ]
}
