import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { getQuizLeaderBoardDataAction } from "redux/marketing/actions/user"
import { appActions } from "redux/app/slice/app"
import QuizSummaryTable from "./quizSummaryTable"
import ToggleSwitch from "components/StyledComponents/ToggleSwitch"
import { Typography } from "@mui/material"
import styles from "./quiz.module.scss"

const QuizLeaderBoard = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState(1)
  const { quizLeaderBoardData } = useSelector((state) => state?.marketing ?? [])
  const dispatch = useDispatch()
  const { date, employeeClass, weeks, orderBy } = useSelector((store) => store?.app?.filters || {})
  const [activeToggle, setActiveToggle] = useState("count")

  const handleToggleChange = (toggleName) => {
    setActiveToggle(toggleName)
  }
  useEffect(() => {
    setCurrentPage(1)
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Quiz LeaderBoard"
    dispatch(appActions.initFilters())
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [date, employeeClass, orderBy])

  useEffect(() => {
    dispatch(
      getQuizLeaderBoardDataAction({
        activeToggle,
        date,
        employeeClass,
        currentPage,
        weeks,
        orderBy
      })
    )
  }, [currentPage, date, employeeClass, activeToggle, weeks, orderBy])

  useEffect(() => {
    let payload = {}
    payload.weeks = {
      label: "All",
      range: null,
      value: {
        start: null,
        end: null
      }
    }
    dispatch(appActions.setFilters(payload))
  }, [date])

  useEffect(() => {
    const totalPages = quizLeaderBoardData?.pages
    setPages(totalPages)
  }, [quizLeaderBoardData])

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title="Quiz Leaderboard" breadCrumbs={breadCrumbs}></Header>
          </Col>
        </Row>
        <Row className="mb-3">
          {quizLeaderBoardData?.results?.length > 0 && (
            <Col className={styles.toggleGroup}>
              <div className={styles.toggleItem}>
                <ToggleSwitch
                  checked={activeToggle === "count"}
                  onChange={() => handleToggleChange("count")}
                />
                <Typography className={styles.toggleLabel}>Count</Typography>
              </div>
              <div className={styles.toggleItem}>
                <ToggleSwitch
                  checked={activeToggle === "percentage"}
                  onChange={() => handleToggleChange("percentage")}
                />
                <Typography className={styles.toggleLabel}>Percentage</Typography>
              </div>
              <div className={styles.toggleItem}>
                <ToggleSwitch
                  checked={activeToggle === "time"}
                  onChange={() => handleToggleChange("time")}
                />
                <Typography className={styles.toggleLabel}>Time</Typography>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg="12">
            <QuizSummaryTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={pages}
              rows={quizLeaderBoardData?.results}
              activeToggle={activeToggle}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default QuizLeaderBoard
