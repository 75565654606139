import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { Typography } from "@mui/material"
import ImageDisplay from "components/ImageDisplayer"
import { useTheme } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import Paragraph from "components/ReadMoreParagraph"
import { DMVActions } from "redux/DMV/slice/user"
import {
  addarchiveSpiritItemsAction,
  deleteSpiritMenuItemAction,
  getSpiritItemsAction
} from "redux/marketing/actions/user"
import editIcon from "assets/icons/edit.svg"
import archiveIcon from "assets/icons/UnarchiveItem.svg"
import trashIcon from "assets/icons/trash.svg"
import { formatMenuDate } from "helpers/functions"

function SpiritMenuListItems({
  foodList,
  categoryIdData,
  isEdit,
  setMenuItemsModal = () => {},
  setIsEditModalItem = () => {}
}) {
  const { menuCategory } = useSelector((state) => state.dmv)
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const [ArchiveModal, setArchiveItemModal] = useState(false)
  const dispatch = useDispatch()

  const handleClick = (type, id, categoryId) => {
    dispatch(DMVActions.setDMVItemID(id))
    dispatch(DMVActions.setDMVMenuCategoryId(categoryId))
    dispatch(getSpiritItemsAction())
    if (type == "edit") {
      handleEditMenuItemModal()
    } else if (type == "archive") {
      handleArchiveItem()
    } else {
      handleMenuDeleteModal()
    }
  }

  function handleMenuItemDelete() {
    dispatch(deleteSpiritMenuItemAction())
  }

  function handleArchiveItem() {
    setArchiveItemModal(true)
  }

  function handleCloseArchiveModal() {
    setArchiveItemModal(false)
  }

  function handleArchiveMenuItem() {
    const payload = {
      item_state: "archived"
    }
    dispatch(addarchiveSpiritItemsAction(payload))
  }

  function checkDetailView() {
    if (menuCategory?.list === "Summary View" && !isEdit) {
      return false
    } else {
      return true
    }
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(DMVActions.setDMVItemID(""))
  }

  function handleEditMenuItemModal() {
    setIsEditModalItem(true)
    setMenuItemsModal(true)
  }

  function handleMenuDeleteModal() {
    setDeleteModal(true)
  }

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this spirit ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are you sure you want to archive this spirit?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleArchiveMenuItem()}
        open={ArchiveModal}
        btnText="Archive"
        type="warning"
      />
      <div
        style={{
          border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
          borderRadius: `${isEdit ? "6px" : "0px"}`,
          padding: `${isEdit ? "8px" : "0px"}`,
          margin: "24px 0"
        }}>
        <div className={classes.itemContainer} style={{ margin: 0 }}>
          <div className={classes.imageContainer}>
            <ImageDisplay data={foodList?.image_url}></ImageDisplay>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 375,
              overflowWrap: "break-word"
            }}>
            <div>
              <Typography variant="h4">
                {foodList?.name ?? "-"}
                {foodList?.is_newly_added ? <span className={classes.new_tag}>New</span> : ""}
              </Typography>
            </div>

            <div>
              <div className="mt-2 d-flex flex-column">
                <Typography variant="menuLabel">{"Description:"}</Typography>
                <Typography variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.description ?? "-"}
                </Typography>
              </div>
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Region:"}</Typography>

                  <Typography variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                    {foodList?.origin ?? "-"}
                  </Typography>
                </div>
              )}
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Unique Facts:"}</Typography>
                  {foodList?.unique_facts?.length === 1
                    ? foodList?.unique_facts?.map((item, index) =>
                        item?.fact !== "" ? (
                          <Typography
                            component="div"
                            style={{
                              display: item?.fact ? "list-item" : "",
                              marginLeft: 15
                            }}
                            key={index}
                            variant="menuLabelItalic"
                            sx={{ opacity: 0.6 }}>
                            <Paragraph
                              text={item?.fact ?? ""}
                              maxCharCount={70}
                              key={index}
                              variant="menuLabelItalic"
                              sx={{ opacity: 0.6 }}></Paragraph>
                          </Typography>
                        ) : null
                      )
                    : foodList?.unique_facts?.map((item, index) => (
                        <Typography
                          component="div"
                          style={{
                            display: item?.fact ? "list-item" : "",
                            marginLeft: 15
                          }}
                          key={index}
                          variant="menuLabelItalic"
                          sx={{ opacity: 0.6 }}>
                          <Paragraph
                            text={item?.fact ?? ""}
                            maxCharCount={70}
                            key={index}
                            variant="menuLabelItalic"
                            sx={{ opacity: 0.6 }}></Paragraph>
                        </Typography>
                      ))}
                </div>
              )}
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Ingredients:"}</Typography>
                  {foodList?.ingredient?.map((item, index) => (
                    <Typography key={index} variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                      {`${item?.name ?? ""}`}
                      {item?.measurement
                        ? foodList?.ingredient?.length - 1 > index
                          ? ` (${item?.measurement})`
                          : item?.measurement
                          ? ` (${item?.measurement}) `
                          : ""
                        : foodList?.ingredient?.length - 1 > index
                        ? ", "
                        : ""}
                    </Typography>
                  ))}
                </div>
              )}
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Nose:"}</Typography>
                  <Typography component="div" variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                    {typeof foodList?.nose === "string" && foodList?.nose
                      ? foodList.nose?.includes("\n")
                        ? foodList.nose
                            .split("\n")
                            .map((line, index) =>
                              line.length > 70 ? (
                                <Paragraph key={index} text={line} maxCharCount={70} />
                              ) : (
                                <div key={index}>{line}</div>
                              )
                            )
                        : foodList?.nose
                      : ""}
                  </Typography>
                </div>
              )}
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Palate:"}</Typography>
                  <Typography component="div" variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                    {typeof foodList?.palette === "string" && foodList?.palette
                      ? foodList.palette?.includes("\n")
                        ? foodList.palette
                            .split("\n")
                            .map((line, index) =>
                              line.length > 70 ? (
                                <Paragraph key={index} text={line} maxCharCount={70} />
                              ) : (
                                <div key={index}>{line}</div>
                              )
                            )
                        : foodList?.palette
                      : ""}
                  </Typography>
                </div>
              )}
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Finish:"}</Typography>
                  <Typography component="div" variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                    {typeof foodList?.finish === "string" && foodList?.finish
                      ? foodList.finish?.includes("\n")
                        ? foodList.finish
                            .split("\n")
                            .map((line, index) =>
                              line.length > 70 ? (
                                <Paragraph key={index} text={line} maxCharCount={70} />
                              ) : (
                                <div key={index}>{line}</div>
                              )
                            )
                        : foodList?.finish
                      : ""}
                  </Typography>
                </div>
              )}
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Distillation:"}</Typography>
                  <Typography component="div" variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                    {typeof foodList?.distillation === "string" && foodList?.distillation
                      ? foodList.distillation?.includes("\n")
                        ? foodList.distillation
                            .split("\n")
                            .map((line, index) =>
                              line.length > 70 ? (
                                <Paragraph key={index} text={line} maxCharCount={70} />
                              ) : (
                                <div key={index}>{line}</div>
                              )
                            )
                        : foodList?.distillation
                      : ""}
                  </Typography>
                </div>
              )}
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Aging:"}</Typography>
                  <Typography component="div" variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                    {typeof foodList?.aging === "string" && foodList?.aging
                      ? foodList.aging?.includes("\n")
                        ? foodList.aging
                            .split("\n")
                            .map((line, index) =>
                              line.length > 70 ? (
                                <Paragraph key={index} text={line} maxCharCount={70} />
                              ) : (
                                <div key={index}>{line}</div>
                              )
                            )
                        : foodList?.aging
                      : ""}
                  </Typography>
                </div>
              )}
              {checkDetailView() && (
                <div className="mt-2 d-flex flex-column">
                  <Typography variant="menuLabel">{"Bottling:"}</Typography>
                  <Typography component="div" variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                    {typeof foodList?.bottling === "string" && foodList?.bottling
                      ? foodList.bottling?.includes("\n")
                        ? foodList.bottling
                            .split("\n")
                            .map((line, index) =>
                              line.length > 70 ? (
                                <Paragraph key={index} text={line} maxCharCount={70} />
                              ) : (
                                <div key={index}>{line}</div>
                              )
                            )
                        : foodList?.bottling
                      : ""}
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              {foodList?.price > 0 && <Typography variant="bold">${foodList?.price}</Typography>}
            </div>
            {isEdit && (
              <div className={classes.actionButtonContainer}>
                <div className="d-grid justify-content-center mt-1">
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => handleClick("edit", foodList?.id, categoryIdData)}
                    className={classes.menu_action_icon}
                  />
                </div>
                <div className="d-grid justify-content-center mt-1">
                  <img
                    src={archiveIcon}
                    alt="unarchive"
                    className={classes.menu_action_icon}
                    onClick={() => handleClick("archive", foodList?.id, categoryIdData)}
                  />
                </div>
                <div className="d-grid justify-content-center mt-1">
                  <img
                    onClick={() => handleClick("delete", foodList?.id, categoryIdData)}
                    className={classes.menu_action_icon}
                    src={trashIcon}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-end align-items-end">
          <div>
            <Typography
              component="div"
              sx={{
                fontSize: 14,
                fontWeight: "400"
              }}>
              Added:{" "}
              <Typography
                sx={(theme) => ({
                  fontSize: 14,
                  fontWeight: "400",
                  color: theme.palette.orange.tertiary,
                  cursor: "pointer"
                })}
                variant="tag1">
                {formatMenuDate(foodList?.created_at)}
              </Typography>
            </Typography>
          </div>
          {foodList?.updated_at ? (
            <div>
              <Typography
                component="div"
                sx={{
                  fontSize: 14,
                  fontWeight: "400"
                }}>
                Updated:{" "}
                <Typography
                  sx={(theme) => ({
                    fontSize: 14,
                    fontWeight: "400",
                    color: theme.palette.orange.tertiary,
                    cursor: "pointer"
                  })}
                  variant="tag1">
                  {formatMenuDate(foodList?.updated_at)}
                </Typography>
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default SpiritMenuListItems
